// src/components/FeaturedProductCarousel.js
import React from "react";
import {
  Box,
  Image,
  Text,
  Stack,
  Button,
  HStack,
  Skeleton,
  SkeletonText,
  Link as ChakraLink,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

const FeaturedProductCarousel = ({ products, isLoading }) => {
  return (
    <HStack overflowX="scroll" spacing="24px">
      {isLoading
        ? [...Array(5)].map((_, index) => (
            <Box
              key={index}
              minWidth="300px"
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              display="flex"
              flexDirection="column"
              height="100%"
            >
              <Skeleton height="300px" />
              <Box
                p="6"
                flex="1"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <Stack spacing="3">
                  <SkeletonText mt="4" mb="4" noOfLines={2} />
                </Stack>
                <Skeleton height="40px" width="100px" mt="auto" />
              </Box>
            </Box>
          ))
        : products.map((product) => (
            <Box
              key={product.id}
              minWidth="240px"
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              display="flex"
              flexDirection="column"
              height="100%"
            >
              <ChakraLink
                as={RouterLink}
                to={`/product/${product.slug}`}
                flex="1"
              >
                <Box
                  height="200px" // Define a fixed height for the image container
                  width="100%"
                >
                  <Image
                    src={product.images[0]?.src}
                    alt={product.name}
                    width="100%"
                    height="100%" // Make the image fill the container
                    objectFit="cover" // Ensure the image scales properly
                    _hover={{
                      transform: "scale(1.05)",
                      transition: "transform 0.3s",
                    }}
                  />
                </Box>
              </ChakraLink>
              <Box
                p="6"
                flex="1"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <Stack spacing="3">
                  {/* Display the first product category name above the product title in lowercase */}
                  {product.categories && product.categories.length > 0 && (
                    <Text fontSize="xs" textTransform="uppercase">
                      {product.categories[0].name.toLowerCase()}
                    </Text>
                  )}
                  <Text fontWeight="bold" fontSize="md" noOfLines={2} h="3em" lineHeight="1.5em">
                    {product.name}
                  </Text>
                  <Text fontSize="lg">
                    {parseFloat(product.sale_price) <
                    parseFloat(product.regular_price) ? (
                      <>
                        <span
                          style={{
                            textDecoration: "line-through",
                            color: "red",
                          }}
                        >
                          {product.regular_price} €
                        </span>{" "}
                        <span>{product.sale_price} €</span>
                      </>
                    ) : (
                      <span>{product.regular_price} €</span>
                    )}
                  </Text>
                </Stack>
                <Button
                  as={RouterLink}
                  to={`/product/${product.slug}`}
                  colorScheme="pink"
                  mt="4"
                  size="sm"
                >
                  Περισσότερα
                </Button>
              </Box>
            </Box>
          ))}
    </HStack>
  );
};

export default FeaturedProductCarousel;
