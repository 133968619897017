// src/components/BannerCarousel.js
import React from 'react';
import { Box, HStack } from '@chakra-ui/react';

const BannerCarousel = () => {
    return (
        <HStack overflowX="scroll" spacing="30px">
           <Box
            id='ad-banner-1'
            as="iframe"
            src="//go.linkwi.se/delivery/ih.php?cn=11593-3520&amp;an=CD15770&amp;target=_blank&amp;"
            width="252px"
            height="254px"
            border="0"
            borderRadius="md"
            boxShadow="lg"
            allowFullScreen
            />
            
            <Box
            id='ad-banner-2'
            as="iframe"
            src="//go.linkwi.se/delivery/ih.php?cn=13333-6&amp;an=CD15770&amp;target=_blank&amp;"
            width="252px"
            height="254px"
            border="0"
            borderRadius="md"
            boxShadow="lg"
            allowFullScreen
            />

            <Box
            id='ad-banner-3'
            as="iframe"
            src="//go.linkwi.se/delivery/ih.php?cn=10553-1060&amp;an=CD15770&amp;target=_blank&amp;"
            width="252px"
            height="254px"
            border="0"
            borderRadius="md"
            boxShadow="lg"
            allowFullScreen
            />

            <Box
            id='ad-banner-4'
            as="iframe"
            src="//go.linkwi.se/delivery/ih.php?cn=13026-1781&amp;an=CD15770&amp;target=_blank&amp;"
            width="252px"
            height="254px"
            border="0"
            borderRadius="md"
            boxShadow="lg"
            allowFullScreen
            />
        </HStack>
    );
  };

export default BannerCarousel;
