import React, { useEffect, useState, useCallback } from 'react';
import { Box, Heading } from '@chakra-ui/react';
import HeroBanner from '../components/HeroBanner';
import FeaturedProductCarousel from '../components/FeaturedProductCarousel';
import TopCategoriesCarousel from '../components/TopCategoriesCarousel';
import BannerCarousel from '../components/BannerCarousel';
import api from '../woocommerce';
import { Helmet } from 'react-helmet-async';
import debounce from 'lodash.debounce';

const Home = () => {
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [menProducts, setMenProducts] = useState([]);
  const [womenProducts, setWomenProducts] = useState([]);
  const [kidsProducts, setKidsProducts] = useState([]);
  const [beautyProducts, setBeautyProducts] = useState([]);
  const [isLoadingFeaturedProducts, setIsLoadingFeaturedProducts] = useState(true);
  const [isLoadingMenProducts, setIsLoadingMenProducts] = useState(true);
  const [isLoadingWomenProducts, setIsLoadingWomenProducts] = useState(true);
  const [isLoadingKidsProducts, setIsLoadingKidsProducts] = useState(true);
  const [isLoadingBeautyProducts, setIsLoadingBeautyProducts] = useState(true);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // URL for categories data
  const fileUrl = 'https://fashion-editor.gr/site-feeds/categories.json';

  // Memoized function to fetch categories
  const fetchAllCategories = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(fileUrl);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const fileData = await response.json(); // Assuming the file is JSON
      const allCategories = fileData.filter(category => category.parent === 0);
      setCategories(allCategories);
      setIsLoading(false);
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
    }
  }, []); // Ensure fetchAllCategories does not change on each render

  // Memoized function to fetch featured products
  const fetchFeaturedProducts = useCallback(
    debounce(async () => {
      try {
        const cachedData = localStorage.getItem('featuredProducts');
        const cachedTimestamp = localStorage.getItem('featuredProductsTimestamp');
        const oneHour = 60 * 60 * 1000; // 1 hour in milliseconds
  
        // Check if cached data exists and is still valid (within 1 hour)
        if (cachedData && cachedTimestamp) {
          const isCacheValid = new Date().getTime() - cachedTimestamp < oneHour;
  
          if (isCacheValid) {
            setFeaturedProducts(JSON.parse(cachedData));
            setIsLoadingFeaturedProducts(false);
            return; // Return early if cache is valid
          } else {
            // Cache has expired, remove it
            localStorage.removeItem('featuredProducts');
            localStorage.removeItem('featuredProductsTimestamp');
          }
        }
  
        // If no valid cache, fetch products
        const response = await api.get('/products', {
          params: {
            featured: true,
            per_page: 8,
            fields: 'id,name,slug,images,price,regular_price,sale_price'
          }
        });
  
        const products = response.data.length > 0 ? response.data : await fetchLatestProducts();
        setFeaturedProducts(products);
  
        // Cache the fetched products and store the current timestamp
        localStorage.setItem('featuredProducts', JSON.stringify(products));
        localStorage.setItem('featuredProductsTimestamp', new Date().getTime());
  
        setIsLoadingFeaturedProducts(false);
      } catch (error) {
        console.error('Error fetching products:', error);
        setIsLoadingFeaturedProducts(false);
      }
    }, 500),
    [] // Empty dependency array ensures this function is only created once
  );
  
  // Function to fetch latest products as a fallback
  const fetchLatestProducts = async () => {
    const response = await api.get('/products', {
      params: {
        orderby: 'date',
        order: 'desc',
        per_page: 8,
        page:1,
        // WooCommerce-specific meta query for featured products
        meta_query: JSON.stringify([
          {
            key: '_featured',
            value: 'yes',
            compare: '=',
          },
        ]),
        fields: 'id,name,slug,images,price,regular_price,sale_price'
      }
    });
    return response.data;
  };

  // Function to fetch latest products as a fallback
  const fetchMenProducts = async () => {
    const response = await api.get('/products', {
      params: {
        category: '3159,4709,3095',
        per_page: 8,
        page:1,
        _fields: 'id,name,slug,images,price,regular_price,sale_price,categories'
      }
    });
    setMenProducts(response.data);
    setIsLoadingMenProducts(false);
  };
  const fetchWomenProducts = async () => {
    const response = await api.get('/products', {
      params: {
        category: '3270,4715',
        per_page: 8,
        page:1,
        _fields: 'id,name,slug,images,price,regular_price,sale_price,categories'
      }
    });
    setWomenProducts(response.data);
    setIsLoadingWomenProducts(false);
  };
  const fetchKidsProducts = async () => {
    const response = await api.get('/products', {
      params: {
        category: '3565,3561,4618',
        per_page: 8,
        page:1,
        _fields: 'id,name,slug,images,price,regular_price,sale_price,categories'
      }
    });
    setKidsProducts(response.data);
    setIsLoadingKidsProducts(false);
  };
  const fetchBeautyProducts = async () => {
    const response = await api.get('/products', {
      params: {
        category: '147,35,136',
        per_page: 8,
        page:1,
        _fields: 'id,name,slug,images,price,regular_price,sale_price,categories'
      }
    });
    setBeautyProducts(response.data);
    setIsLoadingBeautyProducts(false);
  };
  

  // Fetch categories and featured products on initial render
  useEffect(() => {
    fetchAllCategories();
    fetchFeaturedProducts();
    fetchMenProducts();
    fetchWomenProducts();
    fetchKidsProducts();
    fetchBeautyProducts();
  }, [fetchAllCategories, fetchFeaturedProducts]); // Use memoized functions as dependencies

  // Generate JSON-LD for Schema.org
  const generateJsonLd = () => {
    const productsLd = featuredProducts.map(product => ({
      "@type": "Product",
      "name": product.name,
      "image": product.images[0]?.src || '',
      "description": product.short_description,
      "sku": product.sku || '',
      "brand": {
        "@type": "Brand",
        "name": product.attributes.find(attr => attr.slug.toLowerCase() === 'pa_brand')?.options[0] || ''
      },
      "offers": {
        "@type": "Offer",
        "priceCurrency": 'EUR',
        "price": product.price,
        "availability": "https://schema.org/InStock",
        "url": `${window.location.origin}/product/${product.slug}`
      }
    }));

    const categoriesLd = categories.map(category => ({
      "@type": "Category",
      "name": category.name,
      "url": `${window.location.origin}/product-category/${category.slug}`,
      "image": category.image?.src || ''
    }));

    const jsonLd = {
      "@context": "https://schema.org",
      "@type": "WebPage",
      "name": "Fashion-editor.gr",
      "description": "Welcome to the homepage of Fashion-editor.gr where you can find featured products and top categories.",
      "url": 'https://fashion-editor.gr',
      "potentialAction": {
        "@type": "SearchAction",
        "target": `${window.location.origin}/search?query={search_term_string}`,
        "query-input": "required name=search_term_string"
      },
      "mainEntity": [
        ...productsLd,
        ...categoriesLd
      ]
    };

    return (
      <script type="application/ld+json">
        {JSON.stringify(jsonLd)}
      </script>
    );
  };

  return (
    <Box>
      <Helmet>
        <title>Ανδρικά, Γυναικεία & Παιδικά Ρούχα | Παπούτσια & Αξεσουάρ Online - Fashion-editor.gr</title>
        <meta name="Fashion-editor.gr" />
        <meta
          name="description"
          content="Ανακαλύψτε τις τελευταίες τάσεις σε ανδρικά, γυναικεία και παιδικά ρούχα, παπούτσια και αξεσουάρ. Απολαύστε μοναδικές προσφορές και γρήγορη παράδοση στο Fashion-editor.gr."
        />
        <meta
          name="keywords"
          content="Ανδρικά Ρούχα, Γυναικεία Ρούχα, Παιδικά Ρούχα, Παπούτσια Online, Ανδρικά Παπούτσια, Γυναικεία Παπούτσια, Παιδικά Παπούτσια, Αξεσουάρ Μόδας, Ρούχα Online, Ρούχα για Όλη την Οικογένεια, Ρούχα και Παπούτσια Online, Τελευταίες Τάσεις Μόδας, Ρούχα για Κάθε Περίσταση, Στυλάτα Αξεσουάρ, Προσφορές σε Ρούχα και Παπούτσια"
        />
        {/* Sitelinks Searchbox */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "url": "https://fashion-editor.gr",
              "potentialAction": {
                "@type": "SearchAction",
                "target": "${window.location.origin}/search?query={search_term_string}",
                "query-input": "required name=search_term_string"
              }
            }
          `}
        </script>
      </Helmet>
      {generateJsonLd()}
      <HeroBanner />
      <Heading size="lg" mt="8" mb="8" textAlign="center">Νέες Αφίξεις</Heading>
      <FeaturedProductCarousel products={featuredProducts} isLoading={isLoadingFeaturedProducts} />
      <Heading size="lg" mt="8" mb="8" textAlign="center">Προτάσεις για τον Άνδρα</Heading>
      <FeaturedProductCarousel products={menProducts} isLoading={isLoadingMenProducts} />
      <Heading size="lg" mt="8" mb="8" textAlign="center">Προτάσεις για την Γυναίκα</Heading>
      <FeaturedProductCarousel products={womenProducts} isLoading={isLoadingWomenProducts} />
      <Heading size="lg" mt="8" mb="8" textAlign="center">Προτάσεις για το Παίδι</Heading>
      <FeaturedProductCarousel products={kidsProducts} isLoading={isLoadingKidsProducts} />
      <Heading size="lg" mt="8" mb="8" textAlign="center">Προτάσεις Ομορφίας</Heading>
      <FeaturedProductCarousel products={beautyProducts} isLoading={isLoadingBeautyProducts} />
      <Heading size="lg" mt="8" mb="8" textAlign="center">Περισσότερες Επιλογές</Heading>
      <TopCategoriesCarousel categories={categories} isLoading={isLoading} />
      <Heading size="lg" mt="8" mb="8" textAlign="center">Προτάσεις Συνεργατών</Heading>
      <BannerCarousel />
    </Box>
  );
};

export default Home;