import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  SimpleGrid,
  Stack,
  Text,
  Button,
  Image,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Skeleton,
  SkeletonText,
  Heading,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  DrawerHeader,
  DrawerFooter,
  useDisclosure,
  ButtonGroup,
  Link as ChakraLink
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { useSearchParams, Link as RouterLink } from 'react-router-dom';
import api from '../woocommerce';
import Pagination from '../components/Pagination';
import Filters from '../components/Filters';
import { useCallback } from 'react';

const brandsURL = 'https://fashion-editor.gr/site-feeds/brands.json'; // Example URL to a JSON file
const sizesURL = 'https://fashion-editor.gr/site-feeds/sizes.json'; // Example URL to a JSON file
const colorsURL = 'https://fashion-editor.gr/site-feeds/colors.json'; // Example URL to a JSON file

const SearchResults = () => {
  const [products, setProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [isLoadingProducts, setIsLoadingProducts] = useState(true);
  const [isLoadingFilters, setIsLoadingFilters] = useState(true);
  const [filters, setFilters] = useState({
    minPrice: '',
    maxPrice: '',
    brands: [],
    sizes: [],
    colors: [],
    categories: []
  });
  const [initialMinPrice, setInitialMinPrice] = useState(''); // Store initial min price
  const [initialMaxPrice, setInitialMaxPrice] = useState(''); // Store initial max price
  const [pagination, setPagination] = useState({ currentPage: 1, totalPages: 1 });
  const [searchParams, setSearchParams] = useSearchParams();
  const [availableBrands, setAvailableBrands] = useState([]);
  const [availableSizes, setAvailableSizes] = useState([]);
  const [availableColors, setAvailableColors] = useState([]);
  const [priceRange, setPriceRange] = useState({ min: 0, max: null });
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Get the search query from the URL
  const searchQuery = searchParams.get('query');  // Use 'query' instead of 'q'

  useEffect(() => {
    if (searchQuery) {
      applySearchParamsToFilters();
      fetchProducts().then(() => fetchAttributesAndTerms());
    }
  }, [searchQuery, searchParams]);

  const applySearchParamsToFilters = () => {
    const newFilters = { ...filters };

    newFilters.minPrice = searchParams.get('minPrice') || '';
    newFilters.maxPrice = searchParams.get('maxPrice') || '';

    if (searchParams.getAll('brands').length > 0) {
      newFilters.brands = searchParams.getAll('brands');
    }

    if (searchParams.getAll('sizes').length > 0) {
      newFilters.sizes = searchParams.getAll('sizes');
    }

    if (searchParams.getAll('colors').length > 0) {
      newFilters.colors = searchParams.getAll('colors');
    }

    setFilters(newFilters);
    setPagination({ ...pagination, currentPage: parseInt(searchParams.get('page') || 1, 10) });
  };

  const fetchProducts = async () => {
    setIsLoadingProducts(true);
    try {
      const paramsForCurrentPage = {
        search: searchQuery, // Use the search query instead of category
        page: pagination.currentPage,
        per_page: 9,
        min_price: filters.minPrice || undefined,
        max_price: filters.maxPrice || undefined,
        _fields: 'id,name,slug,images,price,price_html,regular_price,sale_price,categories,attributes',
      };

      // Filter by brands
      if (filters.brands.length > 0) {
        paramsForCurrentPage['attribute'] = 'pa_brand';
        paramsForCurrentPage['attribute_term'] = filters.brands.join(',');
      }

      // Filter by sizes
      if (filters.sizes.length > 0) {
        paramsForCurrentPage['attribute'] = 'pa_size';
        paramsForCurrentPage['attribute_term'] = filters.sizes.join(',');
      }

      // Filter by colors
      if (filters.colors.length > 0) {
        paramsForCurrentPage['attribute'] = 'pa_color';
        paramsForCurrentPage['attribute_term'] = filters.colors.join(',');
      }

      const response = await api.get('/products', { params: paramsForCurrentPage });
      const productsData = response.data;

      // Set the current page products
      setProducts(productsData);

      // Update pagination info
      setPagination({
        currentPage: pagination.currentPage,
        totalPages: response.headers['x-wp-totalpages'],
      });

      // Fetch all products for min/max price calculation
      await fetchAllProductsForFilters();

    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setIsLoadingProducts(false);
    }
  };

  const fetchAllProductsForFilters = async () => {
    try {
      const paramsForAllProducts = {
        search: searchQuery, // Apply search query for fetching all products
      };

      const response = await api.get('/custom-api/v1/products', { params: paramsForAllProducts });
      const products = response.data;

      setAllProducts(products);

      // Ensure that products have a price field and filter out undefined/null prices
      const validPrices = products.map(product => parseFloat(product.price)).filter(price => !isNaN(price));

      if (validPrices.length > 0) {
        const minPrice = Math.min(...validPrices);
        const maxPrice = Math.max(...validPrices);

        setInitialMinPrice(minPrice);  // Set the initial min price
        setInitialMaxPrice(maxPrice);  // Set the initial max price

        // Update filters with min and max prices
        setPriceRange({ min: minPrice, max: maxPrice });

        setFilters(prevFilters => ({
          ...prevFilters,
          minPrice: filters.minPrice || minPrice,
          maxPrice: filters.maxPrice || maxPrice,
        }));
      }

    } catch (error) {
      console.error('Error fetching all products for filters:', error);
    }
  };

  const fetchAttributesAndTerms = useCallback(async () => {
    setIsLoadingFilters(true);
    try {
      
      // const brands = [];
      // const brandresponse = await api.get('/custom-api/v1/attribute-terms/brand');
      // brands.push(...brandresponse.data);
      // setAvailableBrands(brands);

      // const sizes = [];
      // const sizeresponse = await api.get('/custom-api/v1/attribute-terms/size');
      // sizes.push(...sizeresponse.data);
      // setAvailableSizes(sizes);

      // const colors = [];
      // const colorresponse = await api.get('/custom-api/v1/attribute-terms/color');
      // colors.push(...colorresponse.data);
      // setAvailableColors(colors);

      const brandresponse = await fetch(brandsURL);
      const brands = await brandresponse.json();
      setAvailableBrands(brands || []);

      const sizeresponse = await fetch(sizesURL);
      const sizes = await sizeresponse.json();
      setAvailableSizes(sizes || []);

      const colorresponse = await fetch(colorsURL);
      const colors = await colorresponse.json();
      setAvailableColors(colors || []);

    } catch (error) {
      console.error('Error fetching attributes and terms', error);
    } finally {
      setIsLoadingFilters(false);
    }
  }, []);  // No dependencies since it reads from a static file


  const handleFilterChange = (e) => {
    const { name, value, checked } = e.target;
    const newFilters = { ...filters };

    console.log('filter-name',name, 'value',value)
    if (name === 'minPrice' || name === 'maxPrice') {
        // Ensure that price filters are always valid numbers
        newFilters[name] = value ? Number(value) : undefined;
    } else if (name === 'price') {
        // Handle price filter object for both min and max together
        newFilters.minPrice = value.minPrice;
        newFilters.maxPrice = value.maxPrice;
    } else {
        // Handle selection of brands, sizes, or colors
        if (checked) {
            newFilters[name] = [...newFilters[name], value]; // Add selected filter
        } else {
            newFilters[name] = newFilters[name].filter(item => item !== value); // Remove unselected filter
        }
    }

    // Update filters and the URL search parameters
    setFilters(newFilters);
    setPagination({ ...pagination, currentPage: 1 });
    updateSearchParams(newFilters, 1);
  };

  const handlePageChange = (newPage) => {
    setPagination(prev => ({ ...prev, currentPage: newPage }));
    updateSearchParams(filters, newPage);
  };

  const updateSearchParams = (newFilters, newPage = pagination.currentPage) => {
    const params = new URLSearchParams();

    if (searchQuery) params.set('query', searchQuery);  // Retain the search query in URL
    if (newFilters.minPrice) params.set('minPrice', newFilters.minPrice);
    if (newFilters.maxPrice) params.set('maxPrice', newFilters.maxPrice);
    newFilters.brands.forEach(brand => params.append('brands', brand));
    newFilters.sizes.forEach(size => params.append('sizes', size));
    newFilters.colors.forEach(color => params.append('colors', color));

    params.set('page', newPage);
    setSearchParams(params);
  };

  const generateProductListJsonLd = () => {
    const itemListElement = products.map((product, index) => ({
      "@type": "ListItem",
      "position": index + 1,
      "url": `${window.location.origin}/product/${product.slug}`
    }));

    return (
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "ItemList",
          "itemListElement": itemListElement
        })}
      </script>
    );
  };

  return (
    <Box p="6" maxW="1320px" mx="auto">
      {generateProductListJsonLd()}

      {/* Breadcrumb */}
      <Box overflowX="auto" whiteSpace="nowrap" mb="6">
        <Breadcrumb display="inline-block">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to="/">Αρχική</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink>Αποτελέσματα Αναζήτησης</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Box>

      <Heading as="h1" size="xl" mb="5">
        Αποτελέσματα αναζήτησης για "{searchQuery || ''}"
      </Heading>

      <ButtonGroup size='sm' isAttached variant='outline' display={{ base: 'block', md: 'none' }}>
        <Button onClick={onOpen}>Filters</Button>
        <IconButton
          aria-label="Filter"
          icon={<HamburgerIcon />}
          onClick={onOpen}
          mb="4"
          mt="4"
        />
      </ButtonGroup>

      <Flex direction={['column', 'row']} gap={6}>
        <Box flex="1" display={{ base: 'none', md: 'block' }}>
          {isLoadingFilters ? (
            <Stack spacing={4}>
              <Skeleton height="20px" />
              <Skeleton height="20px" />
              <Skeleton height="20px" />
            </Stack>
          ) : (
            <Filters
              displayedProducts={allProducts}
              availableBrands={availableBrands}
              availableSizes={availableSizes}
              availableColors={availableColors}
              selectedFilters={filters}
              handleFilterChange={handleFilterChange}
              isLoading={isLoadingFilters}
              initialMinPrice={initialMinPrice}
              initialMaxPrice={initialMaxPrice}
            />
          )}
        </Box>

        <Box flex="3">
          {isLoadingProducts ? (
            <SimpleGrid columns={[2, 4]} spacing="6">
              {[...Array(8)].map((_, index) => (
                <Box key={index} borderWidth="1px" borderRadius="lg" overflow="hidden">
                  <Skeleton height="200px" />
                  <Box p="6">
                    <SkeletonText mt="4" noOfLines={4} spacing="4" />
                  </Box>
                </Box>
              ))}
            </SimpleGrid>
          ) : (
            <>
              <SimpleGrid columns={[2, 4]} spacing="6">
                {products.map(product => (
                  <Box key={product.id} borderWidth="1px" borderRadius="lg" overflow="hidden">
                    <ChakraLink as={RouterLink} to={`/product/${product.slug}`} flex="1">
                      <Image 
                        src={product.images[0]?.src} 
                        alt={product.name} 
                        width="100%" 
                        height="auto" 
                        objectFit="cover"
                        loading="lazy"
                        _hover={{ transform: 'scale(1.05)', transition: 'transform 0.3s' }}
                      />
                    </ChakraLink>
                    <Box p="6">
                      <Stack spacing="3">
                        {/* Display the first product category name above the product title in lowercase */}
                        {product.categories && product.categories.length > 0 && (
                          <Text fontSize="xs" textTransform="uppercase">
                            {product.categories[0].name.toLowerCase()}
                          </Text>
                        )}
                        <Text fontWeight="bold" fontSize="md">{product.name}</Text>
                        <Text fontSize="lg">
                          {parseFloat(product.sale_price) < parseFloat(product.regular_price) ? (
                            <>
                              <span style={{ textDecoration: 'line-through', color: 'red' }}>
                                {product.regular_price} €
                              </span>{' '}
                              <span>{product.sale_price} €</span>
                            </>
                          ) : (
                            <span>{product.regular_price} €</span>
                          )}
                        </Text>
                        <Button as={RouterLink} to={`/product/${product.slug}`} colorScheme="pink" size="sm">
                          View Details
                        </Button>
                      </Stack>
                    </Box>
                  </Box>
                ))}
              </SimpleGrid>

              {pagination.totalPages > 1 && (
                <Pagination
                  currentPage={pagination.currentPage}
                  totalPages={pagination.totalPages}
                  onPageChange={handlePageChange}
                />
              )}
            </>
          )}
        </Box>
      </Flex>

      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth='1px'>
            Filters
          </DrawerHeader>
          <DrawerBody>
            <Filters
              displayedProducts={allProducts}
              availableBrands={availableBrands}
              availableSizes={availableSizes}
              availableColors={availableColors}
              selectedFilters={filters}
              handleFilterChange={handleFilterChange}
              isLoading={isLoadingFilters}
              initialMinPrice={initialMinPrice}
              initialMaxPrice={initialMaxPrice}
            />
          </DrawerBody>
          <DrawerFooter borderTopWidth='1px'>
            <Button variant='outline' mr={3} onClick={onClose}>
              Show Products
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default SearchResults;
