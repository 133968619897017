import React, { useEffect } from 'react';
import { Box, Container } from '@chakra-ui/react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import ReactGA from 'react-ga4';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Configure } from 'react-instantsearch-hooks-web';
import searchInsights from 'search-insights';
import CookieConsent from "react-cookie-consent"; // Import the cookie consent component
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import CategoryPage from './pages/CategoryPage';
import SearchResults from './pages/SearchResults';
import ProductDetail from './pages/ProductDetail';
import FAQ from './pages/FAQ';
import TermsAndConditions from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import NotFound from './pages/NotFound'; // Import the NotFound component

// Initialize Algolia client
// const searchClient = algoliasearch('B7KDCY0LCM', 'dd76ad7b8f7267677361977979406d18');
// // Set the userToken for Algolia Insights
// const setUserToken = (token) => { searchInsights('setUserToken', token); };

// Track page views when the route changes
function usePageTracking() {
  const location = useLocation();

  useEffect(() => {
    // Send pageview for the current path
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);
}

function App() {
  // Initialize Google Analytics once on app load
  useEffect(() => {
    ReactGA.initialize('G-1CDJTNX8NN'); // Replace with your Measurement ID

    // Generate or retrieve user token (you can use localStorage or generate a unique token)
    // const token = localStorage.getItem('userToken') || `user-${Date.now()}`;
    // localStorage.setItem('userToken', token);

    // // Set the userToken for Algolia Insights
    // setUserToken(token);
  }, []);

  // Track each pageview
  usePageTracking();

  return (
    <HelmetProvider>
      {/* <InstantSearch searchClient={searchClient} indexName="wp_searchable_posts" insights={true}>
        <Configure maxValuesPerFacet={100} />  */}
        <Box>
          <Header />
          <Container justifyContent="space-between" alignItems="center" maxW="1320px" mx="auto">
            <Box as="main">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/product-category/:slug" element={<CategoryPage />} />
                <Route path="/search" element={<SearchResults />} />
                <Route path="/product/:slug" element={<ProductDetail />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="*" element={<NotFound />} /> {/* Catch-all route for 404 */}
              </Routes>
            </Box>
          </Container>
          <Footer />
        </Box>
      {/* </InstantSearch> */}
      {/* Add Cookie Consent Popup */}
      <CookieConsent
        location="bottom"
        buttonText="Αποδοχή"
        cookieName="FECookieConsent"
        style={{ background: "grey" }}
        buttonStyle={{ color: "black", fontSize: "13px" }}
        expires={150}
      >
        Στο Fashion-editor.gr χρησιμοποιούμε εργαλεία όπως τα cookies για να σου προσφέρουμε μία κορυφαία προσωποποιημένη εμπειρία, για να σε βοηθήσουμε να βρεις ευκολότερα αυτό που ψάχνεις, καθώς και για την ανάλυση της επισκεψιμότητάς μας.
        <a href="/privacy-policy" style={{ color: "#fff", textDecoration: "underline" }}>
          Μάθε Περισσότερα
        </a>
      </CookieConsent>
    </HelmetProvider>
  );
}

export default App;
