// src/components/Filters.js
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Heading, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Checkbox, Stack, Skeleton, Input, Text, Flex, Button, Tag, TagLabel, TagCloseButton, HStack } from '@chakra-ui/react';
//import CurrentFilterTags from './CurrentFilterTags';

const Filters = ({ displayedProducts, availableBrands, availableSizes, availableColors, selectedFilters, handleFilterChange, isLoading, initialMinPrice, initialMaxPrice, childCategorySlugs }) => {
  const [searchParams] = useSearchParams(); // Access URL params
  const [categorySearch, setCategorySearch] = useState('');
  const [brandSearch, setBrandSearch] = useState('');
  const [sizeSearch, setSizeSearch] = useState('');
  const [colorSearch, setColorSearch] = useState('');
  const  min  = Number(initialMinPrice);
  const  max = Number(initialMaxPrice);
  // Check if `minPrice` and `maxPrice` exist in the URL parameters
  const urlMinPrice = searchParams.get('minPrice');
  const urlMaxPrice = searchParams.get('maxPrice');
  const location = useLocation();
  const navigate = useNavigate(); // useNavigate to modify the URL

  // Set the initial state for minValue and maxValue
  const [minValue, setMinValue] = useState(
    urlMinPrice ? Number(urlMinPrice) : selectedFilters.minPrice || initialMinPrice
  );
  const [maxValue, setMaxValue] = useState(
    urlMaxPrice ? Number(urlMaxPrice) : selectedFilters.maxPrice || initialMaxPrice
  );

  const availableCategories = childCategorySlugs || [];

   // Function to handle removal of a filter
   const handleRemoveFilter = (filterType, filterValue) => {
        const queryParams = new URLSearchParams(location.search);

        if (filterType === 'minPrice' || filterType === 'maxPrice') {
            // Remove price filters
            handleFilterChange({ target: { name: filterType, value: '' } });
            queryParams.delete(filterType);
        } else {
            // Handle non-price filters (categories, brands, sizes, colors)
            const updatedFilter = selectedFilters[filterType].filter(item => item.toString() !== filterValue.toString());
            // Update the filters state
            handleFilterChange({
                target: { name: filterType, value: updatedFilter },
            });

            // If all filters of this type are removed, clear the URL parameter; otherwise, update it
            if (updatedFilter.length === 0) {
                queryParams.delete(filterType);
            } else {
                queryParams.set(filterType, updatedFilter.join(','));
            }
        }

        // Update the URL with the modified query parameters
        navigate({
            pathname: location.pathname,
            search: queryParams.toString(),
        });
    };


   // Handle min price input change
   const handleMinChange = (e) => setMinValue(e.target.value);

   // Handle max price input change
   const handleMaxChange = (e) => setMaxValue(e.target.value);
 
   // Apply the price range filter and update the URL
   const handleApplyFilter = () => {
    const refinedMin = Number(minValue) || min;
    const refinedMax = Number(maxValue) || max;
  
    // Apply the price range filter together in one call
    handleFilterChange({
      target: {
        name: 'price',
        value: { minPrice: refinedMin, maxPrice: refinedMax },
      },
    });
  };
  
   // Reset the filter and remove it from the URL
   const handleResetFilter = () => {
     setMinValue(min);
     setMaxValue(max);

     // Apply the price range filter together in one call
    handleFilterChange({
      target: {
        name: 'price',
        value: { minPrice: min, maxPrice: max },
      },
    });
 
   };
   
  const filteredBrands = availableBrands.filter(brand =>
    brand.name.toLowerCase().includes(brandSearch.toLowerCase()) &&
    displayedProducts.some(product =>
      product.attributes.some(attr =>
        attr.slug === 'pa_brand' &&
        attr.options.map(option => option.toLowerCase()).includes(brand.name.toLowerCase())
      )
    )
  );

  const filteredSizes = availableSizes.filter(size =>
    size.name.toLowerCase().includes(sizeSearch.toLowerCase()) &&
    displayedProducts.some(product =>
      product.attributes.some(attr =>
        attr.slug === 'pa_size' &&
        attr.options.map(option => option.toLowerCase()).includes(size.name.toLowerCase())
      )
    )
  );

  const filteredColors = availableColors.filter(color =>
    color.name.toLowerCase().includes(colorSearch.toLowerCase()) &&
    displayedProducts.some(product =>
      product.attributes.some(attr =>
        attr.slug === 'pa_color' &&
        attr.options.map(option => option.toLowerCase()).includes(color.name.toLowerCase())
      )
    )
  );

  return (
    <Box p="6" borderWidth="1px" borderRadius="lg">
      <Box flex="1" display={{ base: 'none', md: 'block' }}>
        <Heading mb={4}  size='lg' fontSize='24px'>Φίλτρα</Heading>
      </Box>
      <Box mb={4}>
        <HStack spacing={2}>
          {/* Min Price Tag */}
          {selectedFilters.minPrice && selectedFilters.minPrice > initialMinPrice && (
            <Tag size="sm" variant="solid" colorScheme="pink">
              <TagLabel>≥ {selectedFilters.minPrice}€</TagLabel>
              <TagCloseButton onClick={() => handleRemoveFilter('minPrice', selectedFilters.minPrice)} />
            </Tag>
          )}

          {/* Max Price Tag */}
          {selectedFilters.maxPrice && selectedFilters.maxPrice < initialMaxPrice && (
            <Tag size="sm" variant="solid" colorScheme="pink">
              <TagLabel>≤ {selectedFilters.maxPrice}€</TagLabel>
              <TagCloseButton onClick={() => handleRemoveFilter('maxPrice', selectedFilters.maxPrice)} />
            </Tag>
          )}

          {/* Brand Tags */}
          {selectedFilters.brands.map((brandId) => {
              // Find the brand object from availableBrands by matching the id
              const availableBrand = availableBrands.find(brand => brand.id.toString() === brandId.toString());
              return (
                <Tag key={brandId} size="sm" variant="solid" colorScheme="pink">
                  {/* If availableBrand is found, display its name */}
                  <TagLabel>{availableBrand ? availableBrand.name : 'Unknown Brand'}</TagLabel>
                  
                  <TagCloseButton onClick={(e) => {
                    e.stopPropagation(); // Prevents triggering the onClick for the tag itself
                    handleFilterChange({
                      target: {
                        name: 'brands',
                        value: brandId,
                      }
                    });
                  }} />
                </Tag>
              );
            })}

          

          {/* Size Tags */}
          {selectedFilters.sizes.map((sizeId) => {
            // Find the size object from availableSizes by matching the id
            const availableSize = availableSizes.find(size => size.id.toString() === sizeId.toString());

            return (
              <Tag key={sizeId} size="sm" variant="solid" colorScheme="pink">
                {/* If availableSize is found, display its name */}
                <TagLabel>{availableSize ? availableSize.name : 'Unknown Size'}</TagLabel>
                
                <TagCloseButton onClick={(e) => {
                  e.stopPropagation(); // Prevents triggering the onClick for the tag itself
                  handleFilterChange({
                    target: {
                      name: 'sizes',
                      value: sizeId,
                    }
                  });
                }} />
              </Tag>
            );
          })}


          {/* Color Tags */}
          {selectedFilters.colors.map((colorId) => {
            // Find the color object from availableColors by matching the id
            const availableColor = availableColors.find(color => color.id.toString() === colorId.toString());

            return (
              <Tag key={colorId} size="sm" variant="solid" colorScheme="pink">
                {/* If availableColor is found, display its name */}
                <TagLabel>{availableColor ? availableColor.name : 'Unknown Color'}</TagLabel>
                
                <TagCloseButton onClick={(e) => {
                  e.stopPropagation(); // Prevents triggering the onClick for the tag itself
                  handleFilterChange({
                    target: {
                      name: 'colors',
                      value: colorId,
                    }
                  });
                }} />
              </Tag>
            );
          })}

          {/* Category Tags */}
          {selectedFilters.categories.map((catId) => {
              const category = availableCategories.find(cat => cat.id.toString() === catId.toString());
              return (
                  <Tag key={catId} size="sm" variant="solid" colorScheme="pink">
                      <TagLabel>{category ? category.name : 'Unknown Category'}</TagLabel>
                      <TagCloseButton onClick={(e) => {
                          e.stopPropagation();
                          handleFilterChange({
                            target: {
                              name: 'categories',
                              value: catId,
                            }
                          });
                      }} />
                  </Tag>
              );
          })}

        </HStack>
      </Box>
      <Accordion defaultIndex={[0,1]} allowMultiple>
        {/* Price Filter */}
        <AccordionItem>
        <AccordionButton>
          <Box flex="1" textAlign="left">
            Τιμή
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel p="1">
          <Box p="2">
            <Flex gap="1" alignItems="center">
              <Input
                placeholder={`Min ${minValue}€`} // Set the placeholder to the min price
                value={minValue}
                onChange={handleMinChange}
                size="sm"
                type="number"
              />
              <Text>-</Text>
              <Input
                placeholder={`Max ${maxValue}€`} // Set the placeholder to the max price
                value={maxValue}
                onChange={handleMaxChange}
                size="sm"
                type="number"
              />
            </Flex>

            <Flex justifyContent="space-between" mt="4">
              <Button size="sm" onClick={handleResetFilter}>
                Reset
              </Button>
              <Button size="sm" colorScheme="pink" onClick={handleApplyFilter}>
                Apply
              </Button>
            </Flex>
          </Box>
        </AccordionPanel>
      </AccordionItem>

        {/* Category Filter */}
        {availableCategories.length > 1 && (
            <AccordionItem>
                <AccordionButton>
                    <Box flex="1" textAlign="left">
                        Κατηγορίες
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                    {availableCategories.length > 5 && (
                        <Input
                            placeholder="Αναζήτηση..."
                            mb={4}
                            value={categorySearch}
                            onChange={(e) => setCategorySearch(e.target.value)}
                        />
                    )}

                    {/* Sort categories: bring selected (isChecked) to the top, exclude the first category */}
                    <Stack maxHeight="150px" overflowY="auto">
                        {isLoading ? (
                            [...Array(5)].map((_, index) => (
                                <Skeleton key={index} height="20px" />
                            ))
                        ) : (
                          availableCategories
                                .slice(1) // Exclude the first category by slicing from the second item
                                .filter((cat) =>
                                    cat.slug.toLowerCase().includes(categorySearch.toLowerCase())
                                ) // Filter based on search input
                                .sort((a, b) => {
                                    const aChecked = selectedFilters.categories.includes(a.id.toString());
                                    const bChecked = selectedFilters.categories.includes(b.id.toString());

                                    // Sort checked items to the top, keep other items in their natural order
                                    return bChecked - aChecked || a.slug.localeCompare(b.slug);
                                })
                                .map((cat) => (
                                    <Checkbox
                                        key={cat.id}
                                        name="categories"
                                        value={cat.id}
                                        onChange={handleFilterChange}
                                        isChecked={selectedFilters.categories.includes(cat.id.toString())}
                                    >
                                        <Text>{cat.name}</Text>
                                    </Checkbox>
                                ))
                        )}
                    </Stack>
                </AccordionPanel>
            </AccordionItem>
        )}



        {/* Brand Filter */}
        {filteredBrands.length > 0 && (
          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                Μάρκα
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              {filteredBrands.length > 5 && (
                <Input
                  placeholder="Αναζήτηση..."
                  mb={4}
                  value={brandSearch}
                  onChange={(e) => setBrandSearch(e.target.value)}
                />
              )}

              {/* Sort brands: bring the selected ones (isChecked) to the top */}
              <Stack maxHeight="150px" overflowY="auto">
                {isLoading ? (
                  [...Array(5)].map((_, index) => (
                    <Skeleton key={index} height="20px" />
                  ))
                ) : (
                  filteredBrands
                    .sort((a, b) => {
                      const aChecked = selectedFilters.brands.includes(a.id.toString());
                      const bChecked = selectedFilters.brands.includes(b.id.toString());
                      return bChecked - aChecked; // Sort by checked status (true/false)
                    })
                    .map((brand) => (
                      <Checkbox
                        key={brand.id}
                        name="brands"
                        value={brand.id}
                        onChange={handleFilterChange}
                        isChecked={selectedFilters.brands.includes(brand.id.toString())}
                      >
                        <Text>{brand.name}</Text>
                      </Checkbox>
                    ))
                )}
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        )}


       {/* Size Filter */}
      {filteredSizes.length > 0 && (
        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Μέγεθος
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            {filteredSizes.length > 5 && (
              <Input
                placeholder="Αναζήτηση..."
                mb={4}
                value={sizeSearch}
                onChange={(e) => setSizeSearch(e.target.value)}
              />
            )}
            <Stack maxHeight="150px" overflowY="auto">
              {isLoading ? (
                [...Array(5)].map((_, index) => (
                  <Skeleton key={index} height="20px" />
                ))
              ) : (
                filteredSizes
                  .sort((a, b) => {
                    const aChecked = selectedFilters.sizes.includes(a.id.toString());
                    const bChecked = selectedFilters.sizes.includes(b.id.toString());
                    return bChecked - aChecked; // Sort by checked status (true/false)
                  })
                  .map(size => (
                    <Checkbox
                      key={size.id}
                      name="sizes"
                      value={size.id}
                      onChange={handleFilterChange}
                      isChecked={selectedFilters.sizes.includes(size.id.toString())}
                    >
                      <Text>{size.name}</Text>
                    </Checkbox>
                  ))
              )}
            </Stack>
          </AccordionPanel>
        </AccordionItem>
      )}

      {/* Color Filter */}
      {filteredColors.length > 0 && (
        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Χρώμα
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            {filteredColors.length > 5 && (
              <Input
                placeholder="Αναζήτηση..."
                mb={4}
                value={colorSearch}
                onChange={(e) => setColorSearch(e.target.value)}
              />
            )}
            <Stack maxHeight="150px" overflowY="auto">
              {isLoading ? (
                [...Array(5)].map((_, index) => (
                  <Skeleton key={index} height="20px" />
                ))
              ) : (
                filteredColors
                  .sort((a, b) => {
                    const aChecked = selectedFilters.colors.includes(a.id.toString());
                    const bChecked = selectedFilters.colors.includes(b.id.toString());
                    return bChecked - aChecked; // Sort by checked status (true/false)
                  })
                  .map(color => (
                    <Checkbox
                      key={color.id}
                      name="colors"
                      value={color.id}
                      onChange={handleFilterChange}
                      isChecked={selectedFilters.colors.includes(color.id.toString())}
                    >
                      <Text>{color.name}</Text>
                    </Checkbox>
                  ))
              )}
            </Stack>
          </AccordionPanel>
        </AccordionItem>
      )}

      </Accordion>
    </Box>
  );
};

export default Filters;